import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import Carousel from "nuka-carousel/lib/carousel";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";
import CarouselSlide from "./CarouselSlide";
import { graphql } from "gatsby";

const CarouselWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 0;
  padding-top: 200%;
  overflow: hidden;
  position: relative;
  z-index: 9;

  @media (min-width: 768px) {
    padding-top: 100%;
  }

  @media (min-width: 1024) {
    padding-top: 58%;
  }

  @media (min-width: 1200px) {
    padding-top: 58%;
  }

  button {
    background: transparent !important;
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    top: 45%;
    position: absolute;
  }

  .slider-control-centerleft {
    left: -70px;
  }

  .slider-control-centerright {
    right: -70px;
    transform: rotate(180deg) scaleY(-1);
  }

  @media (min-width: 768px) {
    .slider-control-centerleft,
    .slider-control-centerright {
      top: 92%;
    }
    .slider-control-centerleft {
      left: 0;
    }

    .slider-control-centerright {
      right: 0;
    }
  }

  @media (min-width: 1200px) {
    .slider-control-centerleft,
    .slider-control-centerright {
      top: 85%;
    }
  }

  @media (min-width: 768px) {
    .slide.slide-current.slide-visible .sliderNav {
      transform: translateY(164%);
    }
  }

  .mockup {
    position: absolute;
    /* overflow: visible; */
    top: 25%;
    left: 16%;
    transform-origin: 0 0 0;
    transform: ${(props) =>
      `scale3d(${props.screenScale}, ${props.screenScale}, 1)`};

    @media (min-width: 420px) {
      top: 25%;
      left: 13.5%;
    }

    @media (min-width: 768px) {
      top: 40%;
      left: 15.5%;
      overflow: hidden;
    }

    @media (min-width: 1024px) {
      top: 18%;
    }

    @media (min-width: 1200px) {
      top: 10vmax;
      left: 15vmax;
    }

    @media (min-aspect-ratio: 2/1) {
      top: 4vmax;
      left: 15vmax;
    }
    @media (aspect-ratio: 4/3) {
      top: 16vmax;
      left: 15vmax;
    }
  }

  .mobile {
    position: absolute;
    z-index: 100;
    /* width: 205px;
    height: 510px; */
    width: 205px;
    height: 510px;
    top: 0;
    left: 0;
    outline: 1px solid transparent;
    transform-origin: 0 0 0;
    /* prettier-ignore */
    /* transform: matrix3d(0.8, 0, 0, 0, -0.00255959, 0.810414, 0, -3.52237e-05, 0, 0, 1, 0, 18, 6.99999, 0, 1); */
    transform: matrix3d(0.731707, 0, 0, 0, -0.0250798, 0.745508, 0, -0.000250798, 0, 0, 1, 0, 25, 7, 0, 1);
    

    @media (min-width: 420px) {
      width: 205px;
      height: 510px;
      top: 25%;
      left: 25%;
      /* prettier-ignore */
      /* transform: matrix3d(2.41766, -0.00847968,0, -5.46356e-6, 0.00136318, 2.46009, 0, -2.72438e-5, 0, 0, 1, 0, -150.017, -244.01, 0, 1); */
      transform: matrix3d(2.19107, 0.0103362, 0, 0.0000175425, -0.027207, 2.3089, 0, -0.000262155, 0, 0, 1, 0, -123, -248, 0, 1);
    }

    @media (min-width: 768px) {
      width: 1280px;
      /* height: 720px; */
      height: 925px;
      top: 0;
      left: 0;
      /* prettier-ignore */
      transform: matrix3d(0.838677, -0.0000344017, 0, -9.82906e-7, -0.059859, 0.712627, 0, -0.0000936275, 0, 0, 1, 0, 112, 35, 0, 1);
    }
  }

  .mobile img {
    width: 100%;
  }

  .slider-container {
    width: 100%;
    height: 100%;
  }

  .slider-frame {
    height: 100% !important;
    /* overflow: visible !important; */
    top: -10%;
    padding-top: 25%;

    @media (min-width: 768px) {
      overflow: hidden !important;
      top: 0;
      padding-top: 0;
    }
  }
`;

const RefScreen = styled.div`
  display: block;
  width: 300px;
  height: auto;

  @media (min-width: 420px) {
    width: 800px;
  }

  @media (min-width: 768px) {
    width: 1900px;
  }

  .screenImageMobile {
    display: block;
    width: 200px;
    object-fit: contain !important;

    @media (min-width: 420px) {
      width: 600px;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }

  .screenImage {
    display: none;

    @media (min-width: 768px) {
      display: block;
      /* width: 1500px; */
      width: 1300px;
      object-fit: contain !important;
    }
  }

 
`;

const EmblaCarousel = ({ slides, screenImage, screenImageMobile }) => {
  const wrapRef = useRef();
  const screenRef = useRef();

  function useWrapSize() {
    const [wrapSize, setWrapSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setWrapSize([
          wrapRef.current.clientWidth,
          wrapRef.current.clientHeight,
        ]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return wrapSize;
  }

  function useScreenSize() {
    const [screenSize, setScreenSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setScreenSize([
          screenRef.current.clientWidth,
          screenRef.current.clientHeight,
        ]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return screenSize;
  }

  const [wrapWidth, wrapHeight] = useWrapSize();
  const [screenWidth, screenHeight] = useScreenSize();

  const scaleFactor = wrapWidth / screenWidth;

  return (
    <CarouselWrapper ref={wrapRef} screenScale={scaleFactor}>
      <div className="mockup">
        <RefScreen ref={screenRef}>
          <GatsbyImage
            class="screenImage"
            image={getImage(screenImage.asset)}
            alt={screenImage.altText}
            loading="eager"
          />
          <GatsbyImage
            class="screenImageMobile"
            image={getImage(screenImageMobile.asset)}
            alt={screenImageMobile.altText}
            loading="eager"
          />
        </RefScreen>
        <div className="mobile">
          <Carousel
            wrapAround={true}
            slidesToShow={1}
            animation="fade"
            dragging={false}
            swiping={false}
            defaultControlsConfig={{
              nextButtonText: <NextButton />,
              prevButtonText: <PrevButton />,
            }}
            renderBottomCenterControls={() => {}}
          >
            {slides.map((slide, index) => (
              <CarouselSlide slide={slide.node} index={index} />
            ))}
          </Carousel>
        </div>
      </div>
    </CarouselWrapper>
  );
};

export default EmblaCarousel;
