import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "../blocks/button";

const SlideWrapper = styled.div`
  position: relative;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
`;

const SlideImage = styled.div`
  width: 100%;

  .mainImageMobile {
    height: 366px;
    overflow: visible;
  }

  .mainImageDesktop {
    display: none;
  }

  @media(min-width: 768px) {
    .mainImageMobile {
        display: none;
    }
    .mainImageDesktop {
        display: block;
    }
  }
`;

const SlideBottom = styled.div`
  position: absolute;
  top: -13%;
  height: 125%;
  
  transition: .3s all ease-out !important;
  width: 100%;
  text-align: center;
  z-index: 9;

  @media(min-width: 768px) {
    transform: translateY(175%);
    bottom: 0;
    top: auto;
    height: auto;
  }
`;

const SlideContent = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
 h2 {
  font-size: 30px;
  margin: 0;
 }

 a {
  font-size: 20px;
  margin-top: 15px;
 }

@media(min-width: 768px) {
  h2 {
    font-size: 50px;
  }

  a {
    font-size: 30px;
  }
}

`;

export default function RefSlide({ index, slide }) {
  return (
    <SlideWrapper className="embla__slide" key={index}>
      <SlideImage className={`slide-${index}`}>
        <GatsbyImage
          class={`mainImageDesktop mainImage mainImage-${index}`}
          image={getImage(slide.image.asset)}
          alt={slide.image.asset.altText}
          loading="eager"
        />
        <GatsbyImage
          class={`mainImageMobile mainImage mainImage-${index}`}
          image={getImage(slide.image_mobile.asset)}
          alt={slide.image_mobile.asset.altText}
          loading="eager"
        />
      </SlideImage>
      <SlideBottom className="sliderNav">
        <SlideContent className="slideContent">
          <h2>{slide.title}</h2>
          <Button url={slide.link} blank text="zur Seite!" />
        </SlideContent>
      </SlideBottom>
    </SlideWrapper>
  );
}
