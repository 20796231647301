import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import EmblaCarousel from "../components/referenzen/EmblaCarousel";

const RefPageWrapper = styled.div`
overflow: hidden;
height: 100vh;
`

const PageBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;

  .gatsby-image-wrapper {
    width: 100vw;
    height: 100vh;
  }
`;

const RefTemplatePage = ({ data, location }) => {
  const page = data.sanityReferenzen;
  const projects = data.allSanityProjects.edges;


  return (
    <Layout path={location.pathname}>
      <GatsbySeo title={data.sanityReferenzen.seo.title} description={data.sanityReferenzen.seo.description} />
      <RefPageWrapper>
      <EmblaCarousel slides={projects} screenImage={page.screenimage} screenImageMobile={page.screenimage_mobile} />
      <PageBg>
          <GatsbyImage
            image={getImage(page.image.asset)}
            alt={page.image.asset.altText}
            loading="eager"
          />
        </PageBg>
        </RefPageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ReferenzenTemplateQuery {
    sanityReferenzen {
      title
      content: _rawContent
      image {
        asset {
          url
          altText
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      screenimage {
        asset {
          url
          altText
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      screenimage_mobile {
        asset {
          url
          altText
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      seo {
        title
        description
      }
    }
    allSanityProjects(sort: {fields: orderRank}) {
      edges {
        node {
          title
          content: _rawContent
          link
          image {
            asset {
              altText
              gatsbyImageData
            }
          }
          image_mobile {
            asset {
              url
              altText
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          tags
        }
      }
    }
  }
`;

export default RefTemplatePage;
